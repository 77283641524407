import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PlacesAutocomplete, { getLatLng } from 'react-places-autocomplete';

import MapCordinateContext from '@/context/MapCordinateContext';
import {
  HALIFAX_LAT_LANG,
  SEARCH_PLACEHOLDER_EN,
  SEARCH_PLACEHOLDER_FR,
} from '@/utils/constants';
import defaultCenters from '@/utils/default';

import styles from './style.module.scss';

const SmartSearchTextBox = () => {
  const { locale }: any = useRouter();
  const [address, setAddress] = useState('');
  const [isScrolled, setIsScrolled] = useState(false);

  const router = useRouter();
  const searchOptions = {
    componentRestrictions: { country: ['ca'] },
  };

  const cords = useContext(MapCordinateContext);

  const handleChange = async (value: string) => {
    setAddress(value);
  };

  const handleClick = (searchItem: any) => {
    const addr = searchItem && searchItem?.length ? searchItem : address;
    if (
      addr?.toLowerCase().trim() === 'nova scotia' ||
      addr?.toLowerCase().trim() === 'nova scotia, canada'
    ) {
      router.push(
        `/self-storage/?location=${address}&lat=${HALIFAX_LAT_LANG.lat}&lng=${HALIFAX_LAT_LANG.lng}`
      );
    }

    const value = addr && addr?.length ? addr.split(',') : address.split(',');

    if (value) {
      /* eslint-disable*/
      let address = addr;

      const getGeocode = () => {
        const googleMapsGeocoder = new google.maps.Geocoder();
        googleMapsGeocoder.geocode(
            {
              address,
              componentRestrictions: {
                country: 'ca',
              },
            },
            (results) => {
              getLatLng(results[0]).then((latLng) => {
                if(latLng === null || (latLng.lat === 56.130366 && latLng.lng === -106.346771)) {
                  latLng = defaultCenters[0].center;
                }
                if (address.includes('#')) {
                  address = address.replace('#', '')
                }
                const cityName =
                    address?.toLowerCase().includes('halifax') || address?.toLowerCase().trim() === 'nova scotia'
                    || address?.toLowerCase().trim() === 'nova scotia, canada';
                const coords = cityName ? HALIFAX_LAT_LANG : latLng;
                cords.setLatLangs(coords);
                cords?.setSisterInput(false);
                router.push(
                    `/self-storage/?location=${address}&lat=${coords.lat}&lng=${coords.lng}`
                );
              });
            }
        );
      };

      if (typeof google !== 'undefined' && google.maps) {
        getGeocode();
      } else {
        const interval = setInterval(() => {
          if (typeof google !== 'undefined' && google.maps) {
            clearInterval(interval);
            getGeocode();
          }
        }, 250);
      }
    };
  }

  const handleSelect = (value: string) => {
    setAddress(value);
    cords.setAddresses(value);
    handleClick(value);

  };

  useEffect(() => {
    if (address) {
      setAddress(address);
    }
  }, [address]);

  // Log error status and clear dropdown when Google Maps API returns an error.
  const onError = (status: any, clearSuggestions: any) => {
    console.log('Google Maps API returned error with status: ', status);
    clearSuggestions();
  };

  const handleScrolled = useCallback(
    (e: any) => {
      e.preventDefault();
      const scrollValue = Math.floor(window.scrollY);
      if (scrollValue > 295) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    },
    [isScrolled]
  );
  useEffect(() => {
    window.addEventListener('scroll', (e) => handleScrolled(e));
    return () => {
      window.removeEventListener('scroll', () => handleScrolled);
    };
  }, [isScrolled]);

  return (
    <div className={`${styles['sv-smart-search-container']} ${isScrolled ? styles['scroll'] : ''}`}>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={searchOptions}
        googleCallbackName="initTwo"
        onError={onError}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={styles['sv-smart-search']}>
            <input
              className={styles['sv-search-input']}
              {...getInputProps({
                disabled: false,
                placeholder: `${
                  locale === 'en'
                    ? SEARCH_PLACEHOLDER_EN
                    : SEARCH_PLACEHOLDER_FR
                }`,
              })}
            />
            <SearchOutlinedIcon className={styles['sv-search-icon']} style={{fontSize: '28px', marginLeft: 'unset', cursor: 'hand' }} onClick={handleClick}/>
            <div className={styles['sv-find-storage']}>
              <FormattedMessage id="header.find-storage" />
            </div>
            <div className={styles["suggestions-dropdown"]}>
            {loading ? <div>...loading</div> : null}
             {suggestions.map((suggestion, index) => {
                const style = {
                  backgroundColor: suggestion.active ? '#e9ecef' : '#fff',
                };
                return (
                  <>
                    <div
                      className={styles["options"]}
                      {...getSuggestionItemProps(suggestion, { style })}
                      key={index}
                    >
                      {suggestion.description}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};
export default SmartSearchTextBox;
