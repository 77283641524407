import { doDataVaultRequest, doRequest } from '../../utils/request';
import * as urls from '../apiUrl';

const getHeaderAndFooterDetailsWithDefaultLocale = async (
  brand: string = 'as',
  locale: any = 'en'
) => {
  return new Promise((resolve: any, reject: any) => {
    try {
      const data = doRequest(`${urls.getBrandLocaleUrl(brand, locale)}`, 'get');
      resolve(data);
    } catch (e) {
      reject(e);
    }
  });
};

const getHomeBodyDetailsWithDefaultLocale = async (
  brand: string = 'as',
  locale: any = 'en'
) => {
  return new Promise((resolve: any, reject: any) => {
    try {
      const data = doRequest(`${urls.getHomeLocaleUrl(brand, locale)}`, 'get');
      resolve(data);
    } catch (e) {
      reject(e);
    }
  });
};

const getBrandReviews = async () => {
  return doDataVaultRequest(`${urls.GOOGLE_CLIENT_REVIEWS}`, 'get');
};

const getOurBrands = async () => {
  return doRequest(`${urls.GET_OUR_BRANDS}`, 'get');
};

// for future lcode include
const getGoogleReviews = async (lcode: any) => {
  return doDataVaultRequest(`${urls.getClientReviews(lcode)}`, 'get');
};

const getGoogleReview = async (lcode: any, locale: any) => {
  return doDataVaultRequest(`${urls.getClientReview(lcode, locale)}`, 'get');
};

// lat, lng, brand, limit,
const getConvenientLocations = async (
  lang: string,
  cords: any,
  ncards: any = 3
) => {
  return doDataVaultRequest(
    `${urls.CONVENIENT_LOCATIONS}?lat=${cords?.latitude}&lng=${cords?.longitude}&brand=as&limit=${ncards}&lang=${lang}`,
    'get'
  );
};

// brand section
const getInnerPagesWithDefaultLocale = async (
  innerPageName: any,
  brand: string = 'as',
  locale: any = 'en'
) => {
  return doRequest(
    `${urls.getInnerPagesUrl(innerPageName, brand, locale)}`,
    'get'
  );
};

// student special content
const getStudentSpecialContent = async (
  defaultLink: any,
  locale: any,
  brand: string
) => {
  return doRequest(
    `${urls.getStudentSpecialUrl(defaultLink, locale, brand)}`,
    'get'
  );
};

// city landing page
const getCityLandingInfo = async (
  slug: any,
  brand: string = 'as',
  locale: any = 'en'
) => {
  return doRequest(`${urls.getCityLandingInfo(slug, brand, locale)}`, 'get');
};

// city landing page
const getVehicleLandingData = async (
  slug: any,
  brand: string = 'as',
  locale: any = 'en'
) => {
  return doRequest(`${urls.getVehicleLandingInfo(slug, brand, locale)}`, 'get');
};

// right units
const getRightUnitInfo = async (brand: string = 'as', locale: any = 'en') => {
  return doRequest(`${urls.getRightUnitInfo(brand, locale)}`, 'get');
};

// validate city for facility slug

const getValidityCity = async (slug: any, brand: string) => {
  return doRequest(`${urls.getValidateCity(slug, brand)}`, 'get');
};

export {
  getBrandReviews,
  getCityLandingInfo,
  getConvenientLocations,
  getGoogleReview,
  getGoogleReviews,
  getHeaderAndFooterDetailsWithDefaultLocale,
  getHomeBodyDetailsWithDefaultLocale,
  getInnerPagesWithDefaultLocale,
  getOurBrands,
  getRightUnitInfo,
  getStudentSpecialContent,
  getValidityCity,
  getVehicleLandingData,
};
