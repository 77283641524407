import Button from '@mui/material/Button';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './style.module.scss';

interface SmartButtonProps {
  onClick?: () => void;
  text: string;
  hasIcon?: boolean;
  iconProps?: any;
  href?: string;
  textClassName?: string;
  Icon?: any;
  dot?: boolean;
}

const SmartButton = ({
  onClick,
  text,
  iconProps,
  hasIcon = false,
  href,
  textClassName,
  Icon,
  dot,
}: SmartButtonProps) => {
  return (
    <Button
      onClick={onClick}
      style={{
        backgroundColor: '#F2F2F2',
        color: '#1C1B1F',
        borderRadius: '8px',
        padding: '8px 16px',
        boxShadow: 'none',
        minWidth: '20px',
        whiteSpace: 'nowrap',
        fontFamily: 'Hind',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '120%',
      }}
      disableRipple
      className={styles['sv-smart-button']}
      sx={{
        '& .MuiButton-startIcon': { marginRight: '0px', marginLeft: '0px' },
      }}
      variant="contained"
      href={href}
      startIcon={hasIcon ? <Icon {...iconProps} /> : null}
    >
      {text ? (
        <div className={textClassName}>
          <FormattedMessage id={text} />
        </div>
      ) : null}
      {dot && <div className={styles.filterDot} />}
    </Button>
  );
};

export default SmartButton;
